<template>
  <CourierBase :loader_prop="loader_prop">
      <div class="package_section">
          <div class="container">
              <div class="table_wrap">
                  <div class="cell_wrap">
                      <div class="empty_package">
                          <img src="images/empty_package_box.png" alt="Package Empty">
                          <p>:) ברוך הבא למערכת שליחים  {{name}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </CourierBase>
</template>

<script>
import BackendModel from "../../models/BackendModel";
import CourierBase from "./CourierBase";
import('./courier/css/style.css')
export default {
  name: 'Courier',
  components: {
    CourierBase
  },
  data() {
    return {
      loader_prop : true,  
      email : '',
      password: '',
      id : '',
      name: '',
      view_data: []
    }
  },

  async mounted() {
    let backendModel = new BackendModel()
    let view_data = await backendModel.courier_Request("/Api/service/backoffice/courier_home",{})
    this.id =parseInt(view_data.data.auth_res.view_data[0].id)
    this.name = view_data.data.view_data.name
    this.loader_prop = false
  }
  
 

}

</script>

<style>
@import 'courier/css/style.css';
</style>
